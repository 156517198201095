import { Grid, Box, Typography, SxProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useRouter } from 'next/router';
import TransText from 'next-translate/TransText';
import { FC, useEffect } from 'react';
import { ButtonLink } from '@components/Links';

const itemSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    my: 1,
    whiteSpace: 'pre-wrap',
};

const titleSx: SxProps = {
    color: 'grey.100',
    fontSize: 20,
    fontWeight: 'bold',
};

interface BaseErrorProps {
    linkHref?: string;
    linkLabel?: string;
    logo?: string;
    secondaryLinkHref?: string;
    secondaryLinkLabel?: string;
    subtitle?: string;
    title?: string;
    titleVariant?: Variant;
    refreshOnNavigation?: boolean;
}

const BaseError: FC<BaseErrorProps> = ({
    linkHref,
    linkLabel,
    logo = '/assets/error.svg',
    secondaryLinkHref,
    secondaryLinkLabel,
    subtitle,
    title,
    titleVariant,
    refreshOnNavigation = false,
}) => {
    const router = useRouter();

    useEffect(() => {
        if (refreshOnNavigation) {
            router.events.on('routeChangeComplete', () => {
                router.reload();
            });
        }
    }, [refreshOnNavigation, router]);

    return (
        <Grid alignContent="center" direction="column" container>
            <Grid sx={[itemSx, { mb: 3, mt: 9 }]} item>
                <img alt="No hit" src={logo} />
            </Grid>
            {Boolean(title) && (
                <Grid sx={itemSx} item>
                    <Typography
                        sx={[{ textAlign: 'center' }, !titleVariant && titleSx]}
                        variant={titleVariant}
                    >
                        {title}
                    </Typography>
                </Grid>
            )}
            <Grid sx={itemSx} item>
                {subtitle && (
                    <Typography align="center">
                        <TransText components={{ br: <br /> }} text={subtitle} />
                    </Typography>
                )}
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    justifyContent: 'center',
                    mt: 1,
                }}
            >
                {secondaryLinkHref && secondaryLinkLabel && (
                    <ButtonLink
                        data-test-id="base-error-secondary-action-button"
                        href={secondaryLinkHref}
                        openInNewTab={false}
                        sx={itemSx}
                        variant="ghost"
                    >
                        {secondaryLinkLabel}
                    </ButtonLink>
                )}
                {linkHref && linkLabel && (
                    <ButtonLink
                        data-test-id="base-error-action-button"
                        href={linkHref}
                        openInNewTab={false}
                        sx={itemSx}
                        variant="contained"
                    >
                        {linkLabel}
                    </ButtonLink>
                )}
            </Box>
        </Grid>
    );
};

export default BaseError;
