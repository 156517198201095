import { Grid } from '@mui/material';
import { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import BaseError from '@components/BaseError';
import HeaderBar from '@components/HeaderBar';
import { APP_NAME } from '@utils/consts/livv';

const Unavailable: NextPage = () => {
    const { t } = useTranslation('common');

    return (
        <Grid alignItems="center" direction="column" container>
            <HeaderBar title={t('error.unavailable.mainTitle', { appName: APP_NAME })} />
            <Grid sx={{ mt: 4, width: '95vw' }} item>
                <BaseError
                    linkHref="/"
                    linkLabel={t('error.unavailable.link')}
                    logo="/assets/error.svg"
                    subtitle={t('error.unavailable.subtitle')}
                    title={t('error.unavailable.title', { appName: APP_NAME })}
                />
            </Grid>
        </Grid>
    );
};

export default Unavailable;
