import { Link as MaterialLink, LinkProps as MaterialLinkProps } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEvent, forwardRef } from 'react';
import { navBarItemSx } from './navBarItemSx';
import { useFreemiumDialog } from '@utils/context/freemiumDialog';
import useAmplitude from '@utils/hooks/useAmplitude';
import { sxs } from '@utils/mui/sx';
import { NavigationItem } from '@utils/navigation';
import { TrackingEventName } from '@utils/types/amplitude/events';
import { LinkProps } from '@utils/types/link';

type NavBarLinkProps = Pick<NavigationItem, 'isFreeAccess'> & LinkProps<MaterialLinkProps>;

const NavBarLink = forwardRef<HTMLAnchorElement, NavBarLinkProps>(
    ({ href, children, isFreeAccess, onClick, sx = {}, title, ...props }, ref) => {
        const router = useRouter();
        const { openFreemiumDialog } = useFreemiumDialog();
        const { track } = useAmplitude();
        const isActive = router.pathname === href;

        const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
            if (!isFreeAccess && openFreemiumDialog) {
                event.preventDefault();
                openFreemiumDialog();
            }
            if (onClick) onClick(event);

            track({
                name: TrackingEventName.HEADER_NAVIGATION_CLICKED,
                params: {
                    item: title ?? '',
                },
            });
        };

        return (
            <MaterialLink
                ref={ref}
                color={isActive ? 'white' : 'grey.400'}
                component={isFreeAccess ? 'a' : Link}
                href={href}
                onClick={handleClick}
                sx={sxs([navBarItemSx, sx])}
                underline="hover"
                {...props}
            >
                {children}
            </MaterialLink>
        );
    },
);

export default NavBarLink;
